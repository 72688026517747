.filter-container {
  padding: 20px 0;
}
  
.card-button {
 
  font-size: 18px;
}

  
@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 20%;
  }
}
  